import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ModalTaskMudarhoraPage } from 'src/app/pages/modal-task-mudarhora/modal-task-mudarhora.page';
import { ApiTrackFitService } from 'src/app/services/ApiTrackFit/api-track-fit.service';
import { UtilitariosService } from 'src/app/services/utilitarios/utilitarios.service';

@Component({
  selector: 'app-card-paciente-atividades',
  templateUrl: './card-paciente-atividades.component.html',
  styleUrls: ['./card-paciente-atividades.component.scss'],
})
export class CardPacienteAtividadesComponent implements OnInit {

  @Input() dados;
  @Input() origem = 'medico';
  days = [];
  showCard: boolean = true;

  show: boolean = false;

  constructor(public util: UtilitariosService, public loadingController: LoadingController, public alertController: AlertController, public modalController: ModalController, public api: ApiTrackFitService, public actionSheetController: ActionSheetController) {
  }

  toggle() {
    this.show = !this.show;
  }

  ngOnInit() {
    if (this.dados.days) {
      this.days = JSON.parse(this.dados.days);
    }
  }

  async realizado(item) {
    console.log(item)
    this.api.post('/task/' + item.id + '/check', []).then((response: any) => {
      this.util.showToast(response.message);
    });
  }

  icon(type): string {
    return this.util.getTaskIcon(type);
  }

  async opcoes(item) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Opções da tarefa',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Mudar a hora',
        icon: 'time-outline',
        handler: () => {
          this.mudarHora(item);
        }
      }, {
        text: 'Remover Tarefa',
        icon: 'trash-outline',
        handler: () => {
          this.excluirAsk(item);
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
  }

  async mudarHora(item) {
    const modal = await this.modalController.create({
      component: ModalTaskMudarhoraPage,
      componentProps: {
        task: item
      }
    });

    modal.onDidDismiss().then((modalclose: any) => {
      if (modalclose.data.success) {
        this.dados.at = modalclose.data.time;
        this.util.showToast('Hora atualizada com sucesso');
      }
    });

    return await modal.present();
  }

  async excluirAsk(item) {
    const alert = await this.alertController.create({
      header: 'Remover esta tarefa?',
      message: 'Será removida da sua agenda, esta ação é irreverssível, se necessário consulte seu médico.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Excluir',
          handler: () => {
            this.excluir(item);
          }
        }
      ]
    });

    await alert.present();
  }


  async excluir(item) {
    const loading = await this.loadingController.create({
      message: 'Removendo tarefa...'
    });
    await loading.present();
    this.api.delete('/task/' + item.task_id).then((response: any) => {
      this.util.showToast(response.message);
      this.showCard = false;
      loading.dismiss();
    }, (error) => {
      loading.dismiss();
      this.util.showToast(error.message);
    });
  }
}
