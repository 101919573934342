import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-card-paciente',
  templateUrl: './card-paciente.component.html',
  styleUrls: ['./card-paciente.component.scss'],
})
export class CardPacienteComponent implements OnInit {

  @Input() paciente: any;
  @Input() default: any;

  idade: string = 'Idade não informada';

  constructor() { }

  ngOnInit() {

    if (this.paciente.idade && this.paciente.idade != 0) {
      this.idade = this.paciente.idade + ' anos de idade';
    } else if (this.paciente.birth) {
      this.idade = moment().diff(this.paciente.birth, 'years') + ' anos de idade';
    }

  }

}
