import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';
import { ApiTrackFitService } from 'src/app/services/ApiTrackFit/api-track-fit.service';
import { UtilitariosService } from 'src/app/services/utilitarios/utilitarios.service';

@Component({
  selector: 'app-card-agenda',
  templateUrl: './card-agenda.component.html',
  styleUrls: ['./card-agenda.component.scss'],
})
export class CardAgendaComponent implements OnInit {


  @Input() dados;
  days = [];
  showCard: boolean = true;
  @Input() medic = true;

  show: boolean = false;

  constructor(public util: UtilitariosService, public loadingController: LoadingController, public alertController: AlertController,
    public api: ApiTrackFitService, public actionSheetController: ActionSheetController) {
  }

  toggle() {
    this.show = !this.show;
  }

  ngOnInit() {

  }


  async realizado(item) {
    console.log(item)
    this.api.post('/task/' + item.id + '/check', []).then((response: any) => {
      this.util.showToast(response.message);
    });
  }

  icon(type): string {
    return this.util.getTaskIcon(type);
  }

  async opcoes(item) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Opções da tarefa',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Cancelar Consulta',
        icon: 'trash-outline',
        handler: () => {
          this.excluirAsk(item);
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
  }

  async excluirAsk(item) {
    const alert = await this.alertController.create({
      header: 'Remover esta consulta?',
      message: 'Será removida da sua agenda, esta ação é irreverssível, se necessário consulte seu médico.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Excluir',
          handler: () => {
            this.excluir(item);
          }
        }
      ]
    });

    await alert.present();
  }


  async excluir(item) {
    const loading = await this.loadingController.create({
      message: 'Removendo consulta...'
    });
    await loading.present();
    this.api.delete('/task/' + item.task_id).then((response: any) => {
      this.util.showToast(response.message);
      this.showCard = false;
      loading.dismiss();
    }, (error) => {
      loading.dismiss();
      this.util.showToast(error.message);
    });
  }

}
