import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ApiTrackFitService } from 'src/app/services/ApiTrackFit/api-track-fit.service';
import { UtilitariosService } from 'src/app/services/utilitarios/utilitarios.service';

@Component({
  selector: 'app-modal-task-mudarhora',
  templateUrl: './modal-task-mudarhora.page.html',
  styleUrls: ['./modal-task-mudarhora.page.scss'],
})
export class ModalTaskMudarhoraPage implements OnInit {

  @Input() task: any;

  horainicial = '8:00';

  constructor(public modal: ModalController, public alertController: AlertController, public loadingController: LoadingController, public api: ApiTrackFitService, public util: UtilitariosService) { }

  ngOnInit() {
    this.horainicial = this.task.at;
  }

  async mudarHora(event) {
    let value = event.detail.value;
    this.horainicial = value;
  }

  async salvar() {
    const loading = await this.loadingController.create({
      message: 'Salvando...'
    });
    await loading.present();
    this.api.post('/task/changetime/' + this.task.task_id, { time: this.horainicial }).then((data) => {
      loading.dismiss();
      this.modal.dismiss({
        success: true,
        time: this.horainicial
      });
    }, (error) => {
      loading.dismiss();
      this.util.showToast(error.message);
    });
  }

  fechar(): void {
    this.modal.dismiss({
      success: false
    });
  }
}
