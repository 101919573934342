import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class ApiTrackFitService {

  production: boolean = true;
  endpoint: string = 'https://api.trackefit.com.br/api';




  constructor(public http: HttpClient, public platform: Platform) {

    if(this.production){
      this.endpoint = 'https://api.trackefit.com.br/api';
    }else{
      this.endpoint = 'http://127.0.0.1/api';
    }

     /*
    if (window.location.hostname == 'trackefitapp.web.app' || window.location.hostname == 'app.trackefit.com.br') {
      this.endpoint = 'http://127.0.0.1/api';
    }
   
    this.platform.ready().then(() => {
      // 'hybrid' detects both Cordova and Capacitor
      if (this.platform.is('hybrid')) {
        //this.endpoint = 'https://api.arquitecasa.com.br';
        this.endpoint = 'http://127.0.0.1/api';
      }
    });
    */
  }

  getToken = async () => {
    const { value } = await Storage.get({ key: 'trackfit-token' });
    return value;
  };

  get(url) {
    console.log('URL', url)
    return new Promise((resolve, reject) => {

      this.getToken().then((token) => {
        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token),
        };

        this.http.get(this.endpoint + url, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response) => {
          if (err instanceof Response && err.status === 401) {
            this.process401Request(url, null, err)
          }
          reject(err);
        });
      });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {

      var token = this.getToken().then((token) => {

        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token),
        };

        this.http.post(this.endpoint + url, data, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response) => {
          if (err instanceof Response && err.status === 401) {
            this.process401Request(url, data, err)
          }
          reject(err);
        });


      });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {

      var token = this.getToken().then((token) => {

        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token),
        };

        this.http.put(this.endpoint + url, data, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response) => {
          if (err instanceof Response && err.status === 401) {
            this.process401Request(url, data, err)
          }
          reject(err);
        });


      });
    });
  }

  delete(url) {
    return new Promise((resolve, reject) => {

      var token = this.getToken().then((token) => {

        let headers = {
          headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + token),
        };

        this.http.delete(this.endpoint + url, headers).subscribe(res => {
          resolve(res);
        }, (err: Error | Response) => {
          if (err instanceof Response && err.status === 401) {
            this.process401Request(url, null, err)
          }
          reject(err);
        });


      });
    });
  }

  getCep(cep) {
    return new Promise((resolve, reject) => {

      let headers = {
        headers: new HttpHeaders()
          .append('Content-Type', 'application/json')
          .append('Accept', 'application/json')
      };

      this.http.get('https://viacep.com.br/ws/' + cep + '/json/', headers).subscribe(res => {
        resolve(res);
      }, (err: Error | Response) => {
        if (err instanceof Response && err.status === 401) {
          this.process401Request('', null, err)
        }
        reject(err);
      });
    });
  }


  process401Request(url, data, erro) {
    console.log(url, JSON.stringify(erro));
  }

}
