import { Component, Input, OnInit } from "@angular/core";
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { ModalTaskMudarhoraPage } from "src/app/pages/modal-task-mudarhora/modal-task-mudarhora.page";
import { ApiTrackFitService } from "src/app/services/ApiTrackFit/api-track-fit.service";
import { UtilitariosService } from "src/app/services/utilitarios/utilitarios.service";

@Component({
  selector: "app-card-paciente-comprar",
  templateUrl: "./card-paciente-comprar.component.html",
  styleUrls: ["./card-paciente-comprar.component.scss"],
})
export class CardPacienteComprarComponent implements OnInit {
  @Input() dados;
  @Input() origem = "medico";
  days = [];
  showCard: boolean = true;

  show: boolean = false;

  constructor(
    public util: UtilitariosService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public modalController: ModalController,
    public api: ApiTrackFitService,
    public actionSheetController: ActionSheetController
  ) {}

  toggle() {
    this.show = !this.show;
  }

  ngOnInit() {
    if (this.dados.days) {
      this.days = JSON.parse(this.dados.days);
    }
  }

  async realizado(item) {
    console.log(item);
    this.api.post("/task/" + item.id + "/check", []).then((response: any) => {
      this.util.showToast(response.message);
    });
  }

  icon(type): string {
    return this.util.getTaskIcon(type);
  }
}
