import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { AuthGuard } from './guards/auth.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'notificacoes',
    loadChildren: () => import('./pages/notificacoes/notificacoes.module').then(m => m.NotificacoesPageModule)
  },
  {
    path: 'cadastro-paciente',
    loadChildren: () => import('./pages/cadastro-paciente/cadastro-paciente.module').then(m => m.CadastroPacientePageModule)
  },
  {
    path: 'cadastro-medico',
    loadChildren: () => import('./pages/cadastro-medico/cadastro-medico.module').then(m => m.CadastroMedicoPageModule)
  },
  {
    path: 'modal-task-mudarhora',
    loadChildren: () => import('./pages/modal-task-mudarhora/modal-task-mudarhora.module').then(m => m.ModalTaskMudarhoraPageModule)
  },
  {
    path: 'modal-indicar-medicos',
    loadChildren: () => import('./pages/modal-indicar-medicos/modal-indicar-medicos.module').then(m => m.ModalIndicarMedicosPageModule)
  },
  {
    path: 'chat-medico',
    loadChildren: () => import('./pages/chat-medico/chat-medico.module').then(m => m.ChatMedicoPageModule)
  },
  {
    path: 'modal-config-agenda',
    loadChildren: () => import('./pages/modal-config-agenda/modal-config-agenda.module').then(m => m.ModalConfigAgendaPageModule)
  },
  {
    path: 'emailsucesso',
    loadChildren: () => import('./pages/emailsucesso/emailsucesso.module').then(m => m.EmailsucessoPageModule)
  },
  {
    path: 'senhalterada',
    loadChildren: () => import('./pages/senhalterada/senhalterada.module').then(m => m.SenhalteradaPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    FontAwesomeModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
