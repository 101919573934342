import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { ApiTrackFitService } from 'src/app/services/ApiTrackFit/api-track-fit.service';
import { UtilitariosService } from 'src/app/services/utilitarios/utilitarios.service';

@Component({
  selector: 'app-card-paciente-cdai',
  templateUrl: './card-paciente-cdai.component.html',
  styleUrls: ['./card-paciente-cdai.component.scss'],
})
export class CardPacienteCdaiComponent implements OnInit {


  @Input() dados;
  @Input() origem = 'medico';
  days = [];

  formdata: FormGroup;
  questions: boolean = true;
  results: Array<any> = [];
  show: boolean = false;

  constructor(public util: UtilitariosService, public loadingController: LoadingController, public fb: FormBuilder, public api: ApiTrackFitService) {
  }

  toggle() {
    this.show = !this.show;
  }

  ngOnInit() {
    //console.log(this.dados);
    if (this.dados.days) {
      this.days = JSON.parse(this.dados.days);
    }
    this.formdata = this.toFormGroup(this.dados.task_form);
  }

  toFormGroup(questions: Array<any>) {
    const group: any = {};

    if (questions) {
      questions.forEach(question => {

        let value = [];

        if (question.question_type == 'onezero') {
          value = question.question_answers.length > 0 ? question.question_answers[0].question_answer : 1;
        } else if (question.question_type == 'multiple') {
          let arrmultiple = [];
          if (question.question_answers.length > 0) {
            question.question_answers.forEach(arrMult => {
              arrmultiple.push(arrMult.question_answer);
            });
            value = arrmultiple;
          }
        } else {
          value = question.question_answers.length > 0 ? question.question_answers[0].question_answer : '';
        }

        group['question_' + question.question_id] = new FormControl(value, Validators.required);
      });
    } else {
      this.results = this.dados.form_result;
      this.questions = false;
    }
    return new FormGroup(group);
  }

  valor(total) {
    return Math.floor(total);
  }

  async salvarAvaliacao() {

    if (this.formdata.valid) {
      const loading = await this.loadingController.create({
        message: 'Enviando respostas...'
      });
      await loading.present();
      this.api.post('/evaluation/answer/' + this.dados.id, { evaluation: this.prepareData() }).then((response: any) => {
        loading.dismiss();
        this.util.showToast(response.message);
      }, (error) => {
        loading.dismiss();
        this.util.showToast(error.message);
      });
    } else {
      this.util.showToast('Preencha todos os campos');
    }
  }

  icon(type): string {
    return this.util.getTaskIcon(type);
  }


  prepareData() {
    var questions = [];
    this.dados.task_form.forEach(element => {
      let value = this.formdata.get('question_' + element.question_id).value;
      //console.log(value, element);
      var valueFinal = [];

      var dataOptions = element.question_options.filter(item => {
        if (element.question_type == 'multiple') {
          if (value.indexOf(item.option_value) != -1) {
            return true;
          }
        } else {
          return (item.option_label == value);
        }
      });
      //console.log('dataOptions', dataOptions);

      dataOptions.forEach(arrOpt => {
        valueFinal.push({ option_id: arrOpt.option_id, value: arrOpt.option_label });
      });

      if (element.question_type == 'text' || element.question_type == 'number') {
        valueFinal.push({ option_id: 0, value: value });
      }

      questions.push({
        task_question_id: element.question_id,
        type: element.question_type,
        value: valueFinal,
      });
    });
    //console.log('respostas', questions);
    return questions;
  }
}
