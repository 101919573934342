import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CardPacienteAtividadesComponent } from './card-paciente-atividades/card-paciente-atividades.component';
import { CardPacienteAvaliacoesComponent } from './card-paciente-avaliacoes/card-paciente-avaliacoes.component';
import { CardPacienteCdaiComponent } from './card-paciente-cdai/card-paciente-cdai.component';
import { TaskIconComponent } from './task-icon/task-icon.component';
import { CardPacienteComponent } from './card-paciente/card-paciente.component';
import { CardAgendaComponent } from './card-agenda/card-agenda.component';


import { CardPacienteComprarComponent } from './card-paciente-comprar/card-paciente-comprar.component';



const PAGES_COMPONENTS = [
  CardPacienteAtividadesComponent,
  CardPacienteComprarComponent,
  CardPacienteAvaliacoesComponent,
  CardPacienteCdaiComponent,
  TaskIconComponent,
  CardPacienteComponent,
  CardAgendaComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(),
    ReactiveFormsModule
  ],
  declarations: [
    PAGES_COMPONENTS
  ],
  exports: [
    PAGES_COMPONENTS
  ],
  entryComponents: [],
})
export class ComponentsModule { }