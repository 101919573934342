import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Storage } from '@capacitor/storage';
@Injectable({
  providedIn: 'root'
})
export class UtilitariosService {

  private eventoIndicacao = new Subject<any>();

  constructor(public alertController: AlertController, public loadingController: LoadingController, public toastController: ToastController) { }



  async showAlert(msg, title = 'Atenção') {
    const alert = await this.alertController.create({
      cssClass: 'ionalert',
      header: title,
      message: msg,
      buttons: ['OK']
    });
    alert.present();
  }

  async showToast(msg: string, position: string = 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: position == 'bottom' ? 'bottom' : (position == 'top' ? 'top' : 'middle')
    });
    toast.present();
  }

  medicamentoString(item) {

    var a_cada = '';
    var med_period = item.med_period;
    var med_period_val = item.med_period_val;
    var med_period_interval = item.med_period_interval;
    var vezes = item.med_times + " " + item.med_freq;
    if (med_period == "each") {
      a_cada = ", a cada " + med_period_val + " " + med_period_interval;
    }
    var freq_text = a_cada ? a_cada : vezes;
    var continuo = item.continue;
    var med_period_val = item.med_period_val;
    var duration = item.duration;
    var nice_string = continuo !== "Sim" ? " durante " + duration + " dias " : " - uso contínuo ";
    var jejum = item.jejum === "Sim" ? ", em jejum" : "";

    var stringona = item.posology + " " + item.quantity + " " + item.med_type + " " + item.med_way + " " + freq_text + jejum + nice_string;
    return stringona;
  }


  getTaskIcon(type) {
    switch (type) {
      case 1:
      case 'medicacao':
        //Medicação
        return 'medkit-outline';
      case 2:
      case 'atividade':
        //Atividade
        return 'bicycle-outline';
      case 3:
      case 'alimentacao':
        //Alimentação
        return 'nutrition-outline';
      case 4:
      case 'avaliacao':
        //Avaliação
        return 'clipboard-outline';
      case 13:
      case 'mensagem':
        //Mensagem
        return 'mail-outline';
      case 14:
      case 'plano':
        //Plano de Ação
        return 'swap-horizontal-outline';
      case 15:
      case 'exame':
        //Exame
        return 'medkit-outline';
      case 16:
      case 'questionario':
        //Questionario
        return 'document-attach-outline';
      case 18:
      case 'consulta':
        //Relatório Médico
        return 'calendar-outline';
      case 19:
      case 'relatorio':
        //Relatório Médico
        return 'reader-outline';
      default:
        return 'medical-outline';
    }
  }

  fillTaskFormBuilder(item, task_parent_id) {

    if (!item) {

    } else {

      var duration = 1;
      if (item.start_at && item.end_at) {
        var firstDate = moment(item.start_at);
        var secondDate = moment(item.end_at);
        duration = Math.abs(firstDate.diff(secondDate, 'days'));
      }

      var days = JSON.parse(item.days);

      var task = {
        date_type: [item.default_start_report == 'false' ? '1' : '2'],
        task_type_id: [item.task_type_id],
        pacient_id: [item.pacient_id],
        start: [moment(item.start_at).format('DD-MM-YYYY')],
        end: [moment(item.end_at).format('DD-MM-YYYY')],
        default_start_report: [item.default_start_report],
        default_start: [item.default_start],
        default_form: [item.default_form],
        description: [item.description],
        duration: [duration],
        title: [item.title],
        time: [moment(item.start_at).format('hh:mm')],
        objective: [item.objective],
        automation: [task_parent_id],
        days: [days],
        options: [[]],
        question_type: [item.question_type ? item.question_type : '']
      };

      return task;
    }
  }

  async publishIndicacaoes(data: Array<any>) {
    Storage.remove({ key: 'trackfit_indicacoes' });
    if (data.length != 0) {
      Storage.set({ key: 'trackfit_indicacoes', value: JSON.stringify(data) });
      this.eventoIndicacao.next(data);
    }
  }

  getIndicacaoesEvent(): Subject<any> {
    return this.eventoIndicacao;
  }

  getIndicacaoes = async () => {
    const { value } = await Storage.get({ key: 'trackfit_indicacoes' });
    return value;
  };
}
