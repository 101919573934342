import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-icon',
  templateUrl: './task-icon.component.html',
  styleUrls: ['./task-icon.component.scss'],
})
export class TaskIconComponent implements OnInit {

  @Input() type: string;

  icon: string = '';

  constructor() { }

  ngOnInit() {

  }

}
