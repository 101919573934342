import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@capacitor/storage';
const TOKEN_KEY = 'trackfit-token';

import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { ApiTrackFitService } from './ApiTrackFit/api-track-fit.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(private http: HttpClient, public api: ApiTrackFitService) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      //console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email, password }): Observable<any> {
 
    const result =  this.http.post(this.api.endpoint + '/user/login', credentials).pipe(
      map((data: any) => data.access_token),
      switchMap(token => {
  
        return from(Storage.set({ key: TOKEN_KEY, value: token }));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )

    return result;
  }

  loginByToken(token): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      Storage.set({ key: TOKEN_KEY, value: token });
      this.isAuthenticated.next(true);
      resolve('Go!');
    });
  }

  getUserData(force = false): Promise<any> {
    if (force) {
      Storage.remove({ key: 'trackfit_usuario' });
    }
    return new Promise<string>((resolve, reject) => {
      Storage.get({ key: 'trackfit_usuario' }).then((userData: any) => {
        if (userData.value) {
          let user = JSON.parse(userData.value);
          resolve(user);
        } else {
          this.api.get('/user').then((data: any) => {
            Storage.set({ key: 'trackfit_usuario', value: JSON.stringify(data) });
            resolve(data);
          });
        }
      });
    });
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    Storage.remove({ key: 'trackfit_usuario' });
    return Storage.remove({ key: TOKEN_KEY });
  }
}
